<template>
  <b-overlay :show="formShow" rounded="sm" no-fade>
    <b-card>
      <b-row>
        <table class="table table-sm">
          <thead>
            <tr>
              <th scope="col">User</th>
            </tr>
          </thead>
        </table>
      </b-row>

      <validation-observer ref="userRules">
        <b-form>
          <b-row class="mt-2">
            <!-- Name -->
            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group>
                <label for="Name">Name</label>
                <validation-provider name="Name" #default="{ errors }" rules="required">
                  <b-form-input id="name" type="text" v-model="userValue.name"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Surname -->
            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group label="Surname" label-for="surname">
                <validation-provider name="Surname" #default="{ errors }" rules="required">
                  <b-form-input id="surname" type="text" v-model="userValue.surname"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Email -->
            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group label="Email" label-for="email">
                <validation-provider name="Email" #default="{ errors }" rules="required|email">
                  <b-form-input id="email" type="text" v-model="userValue.email"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Password -->
            <!-- v-if="userId == null" -->
            <b-col cols="12" sm="6" md="4" xl="3" v-if="userId == null">
              <b-form-group label="Password" label-for="password">
                <validation-provider name="Password" #default="{ errors }" rules="required">
                  <b-form-input id="password" type="text" v-model="userValue.password"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="4" xl="3" v-else>
              <b-form-group label="Password" label-for="password">
                <b-form-input id="password" type="text" v-model="userValue.password"></b-form-input>
              </b-form-group>
            </b-col>

            <!-- Field: Role -->
            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group label="User Role" label-for="role">
                <validation-provider name="role" #default="{ errors }" rules="required">
                  <v-select v-model="userValue.role" :options="roleOptions" :value="roleOptions" label="name"
                    :clearable="false" input-id="role" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Field: Role -->
            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group label="Status" label-for="status">
                <validation-provider name="status" #default="{ errors }" rules="required">
                  <v-select v-model="userValue.status" :options="statusOptions" :reduce="(val) => val.value"
                    :clearable="false" input-id="status" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Field: Discount Limit -->
            <b-col cols="12" sm="6" md="4" xl="3" v-if="userValue.role != 'supermanager'">
              <b-form-group label="Discount Limit" label-for="discountLimit">
                <validation-provider name="Discount Limit" #default="{ errors }" rules="required">
                  <cleave v-model="userValue.discountLimit" class="form-control" :raw="true"
                    :options="options.percent" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="4" xl="3" v-if="userValue.role != 'supermanager'">
              <b-form-group label="Order Limit" label-for="orderLimit">
                <validation-provider name="Order Limit" #default="{ errors }" rules="required">
                  <cleave v-model="userValue.orderLimit" class="form-control" :raw="true" :options="options.number" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-button v-if="userId == null" type="submit" @click.prevent="formSubmitted" variant="primary"
                class="mr-1 float-right">
                Save
              </b-button>
              <b-button v-else type="submit" @click.prevent="formSubmitted" v-show="userValue.status != 'deleted'"
                variant="primary" class="mr-1 float-right"> Save Changes </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>

    <b-card v-if="userId > 0">
      <b-row>
        <table class="table table-sm">
          <thead>
            <tr>
              <th scope="col">Commmissions Received</th>
            </tr>
          </thead>
        </table>
      </b-row>

      <b-table striped hover :bordered="true" class="position-relative text-center mt-2" selectable responsive
        :small="true" head-variant="dark" show-empty empty-text="No matching records found" :fields="commissionFields"
        :items="userValue.commissionTable">
        <template #cell(id)="row">
          <strong class="text-primary">#{{ Number(row.item.id) }}</strong>
        </template>

        <template #cell(commission)="row">
          <span>${{ row.item.commission ? formatPrice(row.item.commission, 2) : formatPrice(0, 2) }}</span>
        </template>

        <template #cell(actions)="row">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>

            <b-dropdown-item @click="editCommission(row.item)">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>


          </b-dropdown>
        </template>

      </b-table>
    </b-card>

    <b-modal id="modal-closed" cancel-variant="outline-secondary" ok-title="Save" @ok="modalOkClosed"
      cancel-title="Close" no-close-on-backdrop centered title="Close Inventory">
      <b-form>
        <b-form-group class="mr-1" label="Closing User" label-for="h-user">
          {{ userValue.name + ' ' + userValue.surname }}
        </b-form-group>

        <b-form-group :state="modalCommissionState">
          <label for="closingDate">Commission</label>
          <cleave style="height:2.142rem" :state="modalCommissionState" v-model="modalCommission" class="form-control"
            :raw="true" :options="options.number" />


        </b-form-group>
      </b-form>
    </b-modal>


  </b-overlay>
</template>

<script>
import { BCol, BRow, BTab, BTabs, BCard, BAlert, BLink, VBModal } from 'bootstrap-vue';
import { ref, onUnmounted } from '@vue/composition-api';

import store from '@/store';
import userStoreModule from '../userStoreModule';

import { required, alphaNum, email } from '@validations';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import Cleave from 'vue-cleave-component';
import 'cleave.js/dist/addons/cleave-phone.us';
import router from '@/router';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BRow,
    BCol,

    vSelect,
    Cleave,
    ToastificationContent,

    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    Ripple,
    'b-modal': VBModal,
  },

  setup() {
    const USER_APP_STORE_MODULE_NAME = 'user';

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });
  },

  data: function () {
    var userValue = {
      name: null,
      surname: null,
      email: null,
      role: null,
      password: null,
      status: null,
      discountLimit: 0,
      orderLimit: 0,
      commissionTable: [],
    };

    const statusOptions = [
      { label: 'Active', value: 'active' },
      { label: 'Passive', value: 'passive' },
      { label: 'Delete', value: 'deleted' },
    ];



    return {
      userValue: userValue,

      baseURL: store.state.app.baseURL,
      required,
      alphaNum,
      email,

      roleOptions: [],
      statusOptions,
      userId: null,
      formShow: false,
      permissions: [],
      selectedCommission: null,
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },

        percent: {
          numeral: true,
          numeralPositiveOnly: true,
          blocks: [2],
          prefix: '% ',
          rawValueTrimPrefix: true,
          numeralIntegerScale: 2,
        },
      },

      commissionFields: [
        { key: 'date', label: 'DATE', class: 'text-center', thStyle: 'width: 20%' },
        { key: 'quoteId', label: 'QUOTE ID', class: 'text-center', thStyle: 'width: 25%' },
        { key: 'quoteType', label: 'QUOTE TYPE', class: 'text-center', thStyle: 'width: 25%' },
        { key: 'commission', label: 'COMMISSION AMOUNT', class: 'text-center', thStyle: 'width: 25%' },
        { key: 'actions', label: 'ACTIONS', class: 'text-center', thStyle: 'width: 5%' },

      ],

      modalCommission: 0,



    };
  },

  created() {
    this.getRoles();
    this.getUserById();
  },

  methods: {
    getRoles() {
      store
        .dispatch('user/fetchRoles', [])
        .then((res) => {
          this.roleOptions = res.data.data.map((el) => {
            return el.name;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getUserById() {
      this.formShow = true;

      if (router.currentRoute.params.id) {
        this.userId = parseInt(router.currentRoute.params.id);

        store
          .dispatch('user/fetchUser', { id: router.currentRoute.params.id })
          .then((response) => {
            this.userValue = response.data;
            this.formShow = false;
          })
          .catch((error) => {
            if (error.response.status === 404) {
              this.userValue = undefined;
            }
          });
      } else {
        this.formShow = false;
      }
    },

    formSubmitted() {
      this.formShow = true;

      this.$refs.userRules.validate().then((success) => {
        if (success) {
          if (router.currentRoute.params.id) {
            store.dispatch('user/updateUser', this.userValue).then((response) => {
              if (response.status == 200) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Successful',
                    text: '✔️ User Update Successful',
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                });

                this.formShow = false;

                this.getUserById();
              }
            });
          } else {
            store.dispatch('user/addUser', this.userValue).then((response) => {
              if (response.status == 200) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Successful',
                    text: '✔️ User Add Successful',
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                });

                setTimeout(function () {
                  this.formShow = false;
                  router.push({ name: 'user-edit', params: { id: response.data.id } });
                }, 1000);
              }
            });
          }
        } else {
          this.formShow = false;
        }
      });
    },
    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(',', '.');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },

    checkClosingValidity() {
      return this.modalCommission != null && this.modalCommission != '';
    },


    modalOkClosed(bvModalEvent) {
      bvModalEvent.preventDefault();

      if (!this.checkClosingValidity()) {
        return;
      }

      this.$nextTick(() => {
        this.$bvModal.hide('modal-closed');
      });

      this.formShow = true;


      const index = this.userValue.commissionTable.findIndex(item => {
        return (this.selectedCommission === item)
      })
      this.selectedCommission.commission = this.modalCommission;

      this.userValue.commissionTable.splice(index, 1, this.selectedCommission)
      store
        .dispatch('user/updateCommission', this.userValue)
        .then((response) => {
          if (response.status == 200) {
            this.$swal({
              title: 'Success!',
              text: 'Commission table has been updated!',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-success',
              },
              buttonsStyling: false,
            });
            this.formShow = false;

          }
        }).catch((error) => {
          this.formShow = false;
          this.$swal({
            icon: 'error',
            title: 'Something went wrong!',
            text: 'Please try again or report an issue to support',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          });
          this.formShow = false;
        });




    },

    editCommission(item) {
      console.log(item)
      this.selectedCommission = item;
      this.modalCommission = item.commission;
      this.$bvModal.show('modal-closed');
    },



  },
  computed: {
    modalCommissionState() {
      if (this.userRole == 'supermanager') {
        return this.modalCommission != null && this.modalCommission != '' ? true : false;

      }
      else {
        return true
      }
    },
    modalUserState() {
      return this.modalClosingUser != null && this.modalClosingUser != '' ? true : false;
    },
  }
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
